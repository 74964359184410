<script setup lang="ts">
useHead({
  title: 'MetalMusicMadness'
});

const authLoading = useState<boolean>('authLoading', () => true)
const authToken = useState('authToken', () => null)
const showAlertSwitch = useState('showAlert', () => false)
const alertMsg = useState('alertMsg', () => '')
</script>

<template>
  <v-layout class="rounded">
    <v-main class="d-flex align-center justify-center my-2">
      <v-card class="flex-fill mx-2">
        <v-progress-linear indeterminate v-if="authLoading"/>
        <div v-else-if="!authToken" class="text-grey-lighten-1 pa-2">
          Доступ закрыт. Пожалуйста, проверьте подписку на <a class="text-grey-lighten-1" href="https://t.me/mmmvideoblog">паблик MetalMusicMadness</a> и, если подписаны, попробуйте зайти в приложение через пару минут.
        </div>
        <div v-else>
          <NuxtPage />
          <v-snackbar
              color="primary"
              v-model="showAlertSwitch"
              :timeout="2000"
          >
            {{ alertMsg }}

            <template v-slot:actions>
              <v-btn
                  icon="mdi-close"
                  @click="showAlertSwitch = false"
              >

              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </v-card>
    </v-main>
  </v-layout>
</template>

<style scoped>

</style>